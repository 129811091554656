<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Base bibliográfica</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">          
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Base
                  <span>bibliográfica</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista bibliográfica
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarBibliografica')"
                          >
                            <small>+ Cadastrar bibliografia</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Filtro por nome ou autor do livro"
                            @keyup="filtroNomeLivro"
                          >
                        </div>                          
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th class="text-center">
                                  <small class="font-weight-bold">ISBN</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Nome do livro</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Autor(a)</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">ano</small>
                                </th><th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastBibliograficaLoading">
                              <tr>
                                <td
                                  colspan="5"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastBibliograficaPaginacao.length">
                              <tr
                                v-for="(e, index) in fastBibliograficaPaginacao"
                                :key="index"
                              >
                                <td class="align-middle text-center">
                                  <small v-if="e.isbn">
                                    <span v-if="e.isbn.trim()">{{ e.isbn }}</span>
                                    <span v-else>Não registrado</span>
                                  </small>
                                  <small v-else>Não registrado</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small>{{ e.nome_livro }}</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small>{{ e.autora }}</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small>{{ e.ano }}</small>
                                </td>
                                <td class="text-center align-middle">                                  
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarBibliografica(e)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirBibliografica(e)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="5"
                                  class="text-center"
                                >
                                  Nenhum dado bibliográfico cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="fastBibliograficaFiltro"
                            @changePage="
                              fastBibliograficaPaginacao = $event
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">              
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarBibliografica"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova bibliografia</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarBibliografica')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>ISBN</label>
              <input
                v-model="fastBibliograficaNovo.isbn"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do livro</label>
              <input
                v-model="fastBibliograficaNovo.nome_livro"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Descrição</label>
              <textarea
                v-model="fastBibliograficaNovo.descricao"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Autor(a)</label>
              <input
                v-model="fastBibliograficaNovo.autora"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Ano</label>
              <input
                v-model="fastBibliograficaNovo.ano"
                type="text"
                class="form-control"
              >
            </div>                     
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarBibliografica()"
                >
                  Criar bibliografia
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarBibliografica"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar bibliografica</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarBibliografica')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">                    
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>ISBN</label>
              <input
                v-model="fastBibliograficaEditar.isbn"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do livro</label>
              <input
                v-model="fastBibliograficaEditar.nome_livro"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Descrição</label>
              <input
                v-model="fastBibliograficaEditar.descricao"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Autor(a)</label>
              <input
                v-model="fastBibliograficaEditar.autora"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Ano</label>
              <input
                v-model="fastBibliograficaEditar.ano"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarBibliografica()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirBibliografica"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir bibliografia?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirBibliografica')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirBibliografica()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaBaseBibliografica",
  components: {Pagination},
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Bibliografica
      fastBibliograficaTotal: [],
      fastBibliograficaFiltro: [],
      fastBibliograficaPaginacao: [],
      fastBibliograficaLoading: true,
      fastBibliograficaNovo: {
        id_bibliografia: 0,
        isbn: "",
        nome_livro: "",
        descricao: "",
        autora: "",
        ano: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastBibliograficaEditar: {
        id_bibliografia: "",
        isbn: "",
        nome_livro: "",
        descricao: "",
        autora: "",
        ano: "",
        id_plataforma: this.$route.params.id_plataforma
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Recupera bibliografica
            this.getBibliografica(this.$route.params.id_plataforma);
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
            
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getBibliografica(id_plataforma){
      this.promiseGetFastApi("api/projeto_base_bibliografia/lista", "id_plataforma=" + id_plataforma + "&id_bibliografia=0").then(obj => {
        if (obj.length) {
          this.fastBibliograficaTotal = obj
          this.fastBibliograficaFiltro = obj
        }
        else {
          this.fastBibliograficaTotal = []
          this.fastBibliograficaFiltro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastBibliograficaLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar bibliografica", "error");
        this.$store.state.fastCarregando = false;
        this.fastBibliograficaLoading = false
    })
    },
    async criarBibliografica() {
        
        let erros = []
        
        if (!this.fastBibliograficaNovo.nome_livro) erros.push("Campo obrigatório: nome_livro")
        if (!this.fastBibliograficaNovo.descricao) erros.push("Campo obrigatório: descricao")
        if (!this.fastBibliograficaNovo.autora) erros.push("Campo obrigatório: autora")
        if (!this.fastBibliograficaNovo.ano) erros.push("Campo obrigatório: ano")
        if (!this.fastBibliograficaNovo.id_plataforma) erros.push("Campo obrigatório: id_plataforma")

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promiseInserirFastApi(this.fastBibliograficaNovo, "projeto_base_bibliografia").then((res) => {
                this.exibeToasty("Bibliografica criado com sucesso", "success");
                this.hideModal("modalCriarBibliografica");
                this.fastBibliograficaTotal = res
                this.fastBibliograficaFiltro = res
                this.$store.state.fastCarregando = false;
            }).catch((e) => {
                this.exibeToasty("Erro ao criar bibliografica", "error");
                this.$store.state.fastCarregando = false;
            });
        }      
    },
    exibeModalEditarBibliografica(bibliografica) {
      this.fastBibliograficaEditar = bibliografica;
      this.showModal("modalEditarBibliografica");
    },
    async editarBibliografica() {
      
      let erros = []
        
        if (!this.fastBibliograficaEditar.nome_livro) erros.push("Campo obrigatório: nome_livro")
        if (!this.fastBibliograficaEditar.descricao) erros.push("Campo obrigatório: descricao")
        if (!this.fastBibliograficaEditar.autora) erros.push("Campo obrigatório: autora")
        if (!this.fastBibliograficaEditar.ano) erros.push("Campo obrigatório: ano")
        if (!this.fastBibliograficaEditar.id_plataforma) erros.push("Campo obrigatório: id_plataforma")

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promiseAtualizarFastApi(this.fastBibliograficaEditar, "projeto_base_bibliografia").then(e => {
                this.exibeToasty("Salvo com sucesso", "success");                
                this.hideModal("modalEditarBibliografica");
                this.$store.state.fastCarregando = false;
            }).catch(e => {
                this.exibeToasty("Erro ao salvar alterações", "error");
                this.$store.state.fastCarregando = false;
            });
        }        
    },
    exibeModalExcluirBibliografica(bibliografica) {
      this.fastBibliograficaEditar = bibliografica;
      this.showModal("modalExcluirBibliografica");
    },
    async excluirBibliografica(){
        this.$store.state.fastCarregando = true;
        this.promiseExcluirFastApi(this.fastBibliograficaEditar, "projeto_base_bibliografia").then(e => {
            this.exibeToasty("Bibliografica excluído com sucesso", "success");        
            this.getBibliografica(this.$route.params.id_plataforma);   
            this.hideModal("modalExcluirBibliografica");
        }).catch(e => {
            this.exibeToasty("Erro ao excluir", "error");
            this.$store.state.fastCarregando = false;
        });
    },
    filtroNomeLivro(e) {
      let text = e.target.value;
      this.fastBibliograficaFiltro = this.fastBibliograficaTotal.filter(
        (e) => {
          return (
            e.nome_livro.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.autora.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
